import { ThemeProps as Prop, ColorProfiles, Components } from '@powdr/constants';

export const woodwardTheme = {
  [ColorProfiles.BASE]: {
    [Prop.BACKGROUND]: '#FFFFFF',
    [Prop.HEADER]: '#000000',
    [Prop.CONTENT]: '#000000',
    [Prop.LINK_TXT]: '#666666',
    [Prop.LINK_TXT_HOVER]: '#ffd200',
    [Prop.BTN_BG]: '#ffd200',
    [Prop.BTN_TXT]: '#000000',
    [Prop.BTN_BG_HOVER]: '#000000',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#000000',
    [Prop.BTN_TXT_ACTIVE]: '#ffd200',
    [Prop.BTN_BG_INACTIVE]: '#b4b4b4',
    [Prop.BTN_TXT_INACTIVE]: '#FFFFFF',
    [Prop.ELEMENT]: '#000000',
    [Prop.ELEMENT_TXT]: '#FFFFFF',
    [Prop.BORDER]: '#e0e0e0',
    [Prop.ICON]: '#ffd200',
    [Prop.ICON_HOVER]: '#000000',
    [Prop.ICON_2]: '#ffd200',
    [Prop.ICON_HOVER_2]: '#000000',
    [Prop.HEADER_BG]: '#ffd200',
    [Prop.HEADER_TXT]: '#000000',
    [Prop.HEADER_ICON]: '#FFFFFF',
    [Prop.HEADER_BG_2]: '#000000',
    [Prop.HEADER_TXT_2]: '#ffd200',
    [Prop.HEADER_ICON_2]: '#FFFFFF',
    [Prop.ODD_EVEN_OFFSET]: '#e0e0e0',
  },
  [ColorProfiles.PRIMARY]: {
    [Prop.BACKGROUND]: '#ffd200',
    [Prop.HEADER]: '#000000',
    [Prop.CONTENT]: '#000000',
    [Prop.LINK_TXT]: '#000000',
    [Prop.LINK_TXT_HOVER]: '#666666',
    [Prop.BTN_BG]: '#000000',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#000000',
    [Prop.BTN_TXT_HOVER]: '#FFD200',
    [Prop.BTN_BG_ACTIVE]: '#ffffff',
    [Prop.BTN_TXT_ACTIVE]: '#000000',
    [Prop.BTN_BG_INACTIVE]: '#A29B6B',
    [Prop.BTN_TXT_INACTIVE]: '#000000',
    [Prop.ELEMENT]: '#000000',
    [Prop.ELEMENT_TXT]: '#FFFFFF',
    [Prop.BORDER]: '#000000',
    [Prop.ICON]: '#000000',
    [Prop.ICON_HOVER]: '#FFFFFF',
  },
  [ColorProfiles.SECONDARY]: {
    [Prop.BACKGROUND]: '#000000',
    [Prop.HEADER]: '#ffd200',
    [Prop.CONTENT]: '#ffffff',
    [Prop.LINK_TXT]: '#ffffff',
    [Prop.LINK_TXT_HOVER]: '#ffd200',
    [Prop.BTN_BG]: '#ffd200',
    [Prop.BTN_TXT]: '#000000',
    [Prop.BTN_BG_HOVER]: '#ffd200',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#ffffff',
    [Prop.BTN_TXT_ACTIVE]: '#000000',
    [Prop.BTN_BG_INACTIVE]: '#4d4d4d',
    [Prop.BTN_TXT_INACTIVE]: '#ffffff',
    [Prop.ELEMENT]: '#ffd200',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.BORDER]: '#ffd200',
    [Prop.ICON]: '#ffffff',
    [Prop.ICON_HOVER]: '#ffd200',
  },
  [ColorProfiles.TERTIARY]: {
    [Prop.BACKGROUND]: '#3a3a3a',
    [Prop.HEADER]: '#ffd200',
    [Prop.CONTENT]: '#ffffff',
    [Prop.LINK_TXT]: '#ffd200',
    [Prop.LINK_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG]: '#ffd200',
    [Prop.BTN_TXT]: '#000000',
    [Prop.BTN_BG_HOVER]: '#000000',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#000000',
    [Prop.BTN_TXT_ACTIVE]: '#ffffff',
    [Prop.BTN_BG_INACTIVE]: '#757575',
    [Prop.BTN_TXT_INACTIVE]: '#ffd200',
    [Prop.ELEMENT]: '#ffffff',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.BORDER]: '#696969',
    [Prop.ICON]: '#ffffff',
    [Prop.ICON_HOVER]: '#ffd200',
  },
  [ColorProfiles.QUATERNARY]: {
    [Prop.BACKGROUND]: '#ffd200',
    [Prop.HEADER]: '#000000',
    [Prop.CONTENT]: '#000000',
    [Prop.LINK_TXT]: '#000000',
    [Prop.LINK_TXT_HOVER]: '#666666',
    [Prop.BTN_BG]: '#000000',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#000000',
    [Prop.BTN_TXT_HOVER]: '#FFD200',
    [Prop.BTN_BG_ACTIVE]: '#ffffff',
    [Prop.BTN_TXT_ACTIVE]: '#ffd200',
    [Prop.BTN_BG_INACTIVE]: '#A29B6B',
    [Prop.BTN_TXT_INACTIVE]: '#ffd200',
    [Prop.ELEMENT]: '#000000',
    [Prop.ELEMENT_TXT]: '#FFFFFF',
    [Prop.BORDER]: '#000000',
    [Prop.ICON]: '#000000',
    [Prop.ICON_HOVER]: '#FFFFFF',
  },
};

export const componentThemesDefault = {
  [Components.ECOMMERCE_TAB]: {
    [Prop.BORDER]: '#009CDB',
    [Prop.CONTENT]: '#FFFFFF',
    [Prop.BTN_BG_ALT]: '#8BC53F',
    [Prop.BTN_TXT_ALT]: '#FFFFFF',
    [Prop.DATE_SELECT_BG]: '#009CDB',
    [Prop.BTN_CHANGE]: '#5F6368',
  },
  [Components.CALENDAR]: {
    [Prop.BORDER]: '#009CDB',
  },

  [Components.STICKY_NAV]: {
    [Prop.BTN_BG_ACTIVE]: '#FFFFFF',
    [Prop.BTN_TXT_ACTIVE]: '#000000',
  },

  // required for WW theme takeover
  [Components.ALERT_BAR]: {
    [Prop.BACKGROUND]: '#f0f0f0',
    [Prop.HEADER]: '#000000',
    [Prop.CONTENT]: '#022B39',
    [Prop.LINK_TXT]: '#009CDB',
    [Prop.LINK_TXT_HOVER]: '#8BC53F',
    [Prop.BTN_BG]: '#009CDB',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#000000',
    [Prop.BTN_TXT_HOVER]: '#009CDB',
    [Prop.ICON]: '#009CDB',
    [Prop.ICON_HOVER]: '#000000',
  },

  // required for WW theme takeover
  [Components.HEADER]: {
    [Prop.LOGO_BG]: '#000000',
    [Prop.NAV_BG]: '#000000',
    [Prop.NAV_ITEM_TXT_TRANSLUCENT]: '#FFFFFF',
    [Prop.NAV_ITEM_TXT_SOLID]: '#FFFFFF',
    [Prop.NAV_ITEM_TXT_ACTIVE]: '#FFFFFF',
    [Prop.NAV_ITEM_BG_ACTIVE]: '#009CDB',
    [Prop.NAV_ITEM_ACTIVE_ACCENT]: '#009CDB',
    [Prop.WW_ICON_COLOR]: '#FFD200',
  },

  // required for WW theme takeover
  [Components.ECOMMERCE_BANNER]: {
    [Prop.BACKGROUND]: '#000000',
    [Prop.LINK_TXT]: '#ffffff',
    [Prop.LINK_TXT_HOVER]: '#009CDB',
    [Prop.TOGGLE_ACTIVE]: '#009CDB',
    [Prop.TOGGLE_INACTIVE]: '#C4C4BF',
    [Prop.ELEMENT]: '#009CDB',
    [Prop.LINK_SEPARATOR]: '#5a5b5c',
  },

  // required for WW theme takeover
  [Components.WEATHER_FEED]: {
    [Prop.BACKGROUND]: '#009CDB',
    [Prop.ICON]: '#FFFFFF',
    [Prop.CONTENT]: '#000000',
  },

  [Components.DOR]: {
    [Prop.ICON]: '#009CDB',
    [Prop.LINK_TXT]: '#009CDB',
    [Prop.LINK_TXT_HOVER]: '#000000',
    [Prop.BORDER]: '#C4C4BF',
  },

  // required for WW theme takeover
  [Components.PROMOTIONS_FEED]: {
    [Prop.BACKGROUND]: '#FFFFFF',
    [Prop.LINK_TXT]: '#009CDB',
  },

  // required for WW theme takeover
  [Components.PROMOTIONS]: {
    [Prop.PROMO_ITEM_BG]: '#009CDB',
    [Prop.PROMO_ITEM_HEADER]: '#FFFFFF',
    [Prop.PROMO_ITEM_CONTENT]: '#000000',
    [Prop.PROMO_ITEM_BTN_BG]: '#8BC53F',
    [Prop.PROMO_ITEM_BTN_TXT]: '#FFFFFF',
    [Prop.PROMO_ITEM_BTN_BG_HOVER]: '#000000',
    [Prop.PROMO_ITEM_BTN_TXT_HOVER]: '#FFFFFF',
  },

  // required for WW theme takeover
  [Components.MEGA_NAV]: {
    [Prop.LINK_TXT]: '#000000',
    [Prop.LINK_TXT_HOVER]: '#009CDB',
    [Prop.BORDER]: '#C4C4BF',
  },

  // required for WW theme takeover
  [Components.MOBILE_NAV]: {
    [Prop.MENU_BTN_BG]: '#FFFFFF',
    [Prop.MENU_BTN_ICON]: '#009CDB',
    [Prop.LOGO_BG]: '#FFFFFF',
    [Prop.CLOSE_BTN_BG]: '#009CDB',
    [Prop.CLOSE_BTN_ICON]: '#FFFFFF',
    [Prop.MAIN_NAV_BG]: '#FFFFFF',
    [Prop.MAIN_NAV_TXT]: '#009CDB',
    [Prop.MAIN_NAV_BORDER]: '#C4C4BF',
    [Prop.WW_ICON_COLOR]: '#FFD200',
    [Prop.DRAWER_BTN_BG]: '#FFFFFF',
    [Prop.DRAWER_BTN_TXT]: '#009CDB',
    [Prop.PROMO_BTN_BG]: '#FFFFFF',
    [Prop.PROMO_BTN_TXT]: '#009CDB',
    [Prop.SUB_NAV_BG]: '#009CDB',
    [Prop.SUB_NAV_TXT]: '#FFFFFF',
    [Prop.SUB_NAV_BORDER]: '#C4C4BF',
  },

  // required for WW theme takeover
  [Components.FOOTER]: {
    [Prop.BACKGROUND]: '#000000',
    [Prop.HEADER]: '#FFFFFF',
    [Prop.BTN_BG]: '#009CDB',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#8BC53F',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
  },

  // required for WW theme takeover
  [Components.FOOTER_NAV]: {
    [Prop.LINK_TXT]: '#FFFFFF',
    [Prop.LINK_TXT_HOVER]: '#009CDB',
  },

  // required for WW theme takeover
  [Components.COPYRIGHT]: {
    [Prop.BACKGROUND]: '#009CDB',
    [Prop.LINK_TXT]: '#FFFFFF',
  },
};

export const componentThemesAlt = {
  [Components.ECOMMERCE_WIDGET]: {
    [Prop.BTN_BG_ALT]: '#009CDB',
    [Prop.BTN_TXT_ALT]: '#FFFFFF',
  },

  [Components.ECOMMERCE_TAB]: {
    [Prop.BORDER]: '#8BC53F',
    [Prop.CONTENT]: '#FFFFFF',
    [Prop.BTN_BG_ALT]: '#8BC53F',
    [Prop.BTN_TXT_ALT]: '#FFFFFF',
    [Prop.DATE_SELECT_BG]: '#8BC53F',
    [Prop.BTN_CHANGE]: '#5F6368',
  },

  [Components.CALENDAR]: {
    [Prop.BORDER]: '#8BC53F',
  },

  [Components.STICKY_NAV]: {
    [Prop.BTN_TXT]: '#000000',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG_ACTIVE]: '#FFFFFF',
    [Prop.BTN_TXT_ACTIVE]: '#000000',
  },

  // required for WW theme takeover
  [Components.ALERT_BAR]: {
    [Prop.BACKGROUND]: '#f0f0f0',
    [Prop.HEADER]: '#8BC53F',
    [Prop.CONTENT]: '#000000',
    [Prop.LINK_TXT]: '#8BC53F',
    [Prop.LINK_TXT_HOVER]: '#009CDB',
    [Prop.BTN_BG]: '#8BC53F',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#000000',
    [Prop.BTN_TXT_HOVER]: '#8BC53F',
    [Prop.ICON]: '#8BC53F',
    [Prop.ICON_HOVER]: '#000000',
  },

  // required for WW theme takeover
  [Components.HEADER]: {
    [Prop.LOGO_BG]: '#000000',
    [Prop.NAV_BG]: '#000000',
    [Prop.NAV_ITEM_TXT_TRANSLUCENT]: '#FFFFFF',
    [Prop.NAV_ITEM_TXT_SOLID]: '#FFFFFF',
    [Prop.NAV_ITEM_TXT_ACTIVE]: '#000000',
    [Prop.NAV_ITEM_BG_ACTIVE]: '#8BC53F',
    [Prop.NAV_ITEM_ACTIVE_ACCENT]: '#8BC53F',
    [Prop.WW_ICON_COLOR]: '#FFD200',
  },

  // required for WW theme takeover
  [Components.ECOMMERCE_BANNER]: {
    [Prop.BACKGROUND]: '#000000',
    [Prop.LINK_TXT]: '#ffffff',
    [Prop.LINK_TXT_HOVER]: '#8BC53F',
    [Prop.TOGGLE_ACTIVE]: '#8BC53F',
    [Prop.TOGGLE_INACTIVE]: '#C4C4BF',
    [Prop.ELEMENT]: '#8BC53F',
    [Prop.LINK_SEPARATOR]: '#5a5b5c',
  },

  // required for WW theme takeover
  [Components.WEATHER_FEED]: {
    [Prop.BACKGROUND]: '#8BC53F',
    [Prop.ICON]: '#FFFFFF',
    [Prop.CONTENT]: '#000000',
  },

  // required for WW theme takeover
  [Components.DOR]: {
    [Prop.ICON]: '#8BC53F',
    [Prop.LINK_TXT]: '#8BC53F',
    [Prop.LINK_TXT_HOVER]: '#000000',
    [Prop.BORDER]: '#8BC53F',
    [Prop.COMPONENT_BORDER]: '#C4C4BF',
  },

  // required for WW theme takeover
  [Components.PROMOTIONS_FEED]: {
    [Prop.BACKGROUND]: '#FFFFFF',
    [Prop.LINK_TXT]: '#8BC53F',
  },

  // required for WW theme takeover
  [Components.PROMOTIONS]: {
    [Prop.PROMO_ITEM_BG]: '#8BC53F',
    [Prop.PROMO_ITEM_HEADER]: '#FFFFFF',
    [Prop.PROMO_ITEM_CONTENT]: '#000000',
    [Prop.PROMO_ITEM_BTN_BG]: '#000000',
    [Prop.PROMO_ITEM_BTN_TXT]: '#009CDB',
    [Prop.PROMO_ITEM_BTN_BG_HOVER]: '#009CDB',
    [Prop.PROMO_ITEM_BTN_TXT_HOVER]: '#FFFFFF',
  },

  // required for WW theme takeover
  [Components.MEGA_NAV]: {
    [Prop.LINK_TXT]: '#000000',
    [Prop.LINK_TXT_HOVER]: '#8BC53F',
    [Prop.BORDER]: '#C4C4BF',
  },

  // required for WW theme takeover
  [Components.MOBILE_NAV]: {
    [Prop.MENU_BTN_BG]: '#FFFFFF',
    [Prop.MENU_BTN_ICON]: '#8BC53F',
    [Prop.LOGO_BG]: '#FFFFFF',
    [Prop.CLOSE_BTN_BG]: '#000000',
    [Prop.CLOSE_BTN_ICON]: '#8BC53F',
    [Prop.MAIN_NAV_TXT]: '#000000',
    [Prop.MAIN_NAV_BG]: '#FFFFFF',
    [Prop.MAIN_NAV_BORDER]: '#C4C4BF',
    [Prop.WW_ICON_COLOR]: '#FFD200',
    [Prop.DRAWER_BTN_BG]: '#FFFFFF',
    [Prop.DRAWER_BTN_TXT]: '#8BC53F',
    [Prop.PROMO_BTN_BG]: '#FFFFFF',
    [Prop.PROMO_BTN_TXT]: '#8BC53F',
    [Prop.SUB_NAV_TXT]: '#8BC53F',
    [Prop.SUB_NAV_BG]: '#000000',
    [Prop.SUB_NAV_BORDER]: '#C4C4BF',
  },

  // required for WW theme takeover
  [Components.FOOTER]: {
    [Prop.BACKGROUND]: '#000000',
    [Prop.HEADER]: '#8BC53F',
    [Prop.BTN_BG]: '#8BC53F',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#009CDB',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
  },

  // required for WW theme takeover
  [Components.FOOTER_NAV]: {
    [Prop.LINK_TXT]: '#FFFFFF',
    [Prop.LINK_TXT_HOVER]: '#8BC53F',
  },

  // required for WW theme takeover
  [Components.COPYRIGHT]: {
    [Prop.BACKGROUND]: '#8BC53F',
    [Prop.LINK_TXT]: '#FFFFFF',
  },
};
