import {
  Components, ColorProfiles,
} from '@powdr/constants';

// Color Profiles
const {
  BASE, PRIMARY, SECONDARY, TERTIARY, QUATERNARY,
} = ColorProfiles;

export const config = {
  [Components.HEADER]: QUATERNARY,
  [Components.MAIN_NAV]: BASE,
  [Components.MEGA_NAV]: BASE,
  [Components.MOBILE_NAV]: BASE,
  [Components.ECOMMERCE_BANNER]: QUATERNARY,
  [Components.WEATHER_FEED]: PRIMARY,
  [Components.PROMOTIONS_FEED]: BASE,
  [Components.PROMOTIONS]: PRIMARY,
  [Components.ALERT_BAR]: SECONDARY,
  [Components.STICKY_NAV]: PRIMARY,
  [Components.TERTIARY_NAV]: BASE,
  [Components.FOOTER]: QUATERNARY,
  [Components.FOOTER_NAV]: QUATERNARY,
  [Components.PARTNERS]: QUATERNARY,
  [Components.COPYRIGHT]: PRIMARY,
  [Components.MODAL]: BASE,
  [Components.DOR]: BASE,
  layout: {
    headerHeight: 95,
    headerHeightMobile: 60,
    headerLogoContainerWidth: 280,
    headerFeedContainer: 250,
  },

  // This would fallback to BASE but need example of custom color scheme
  [Components.CALENDAR]: BASE, // TODO: Need to a custome color scheme not using BASE
};
