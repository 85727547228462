import {
  ThemeProps as Prop,
  ColorProfiles,
} from '@powdr/constants';

import { componentThemesDefault } from './shared-theme-data';

export const defaultTheme = {
  [ColorProfiles.BASE]: {
    [Prop.BACKGROUND]: '#FFFFFF',
    [Prop.HEADER]: '#000000',
    [Prop.CONTENT]: '#000000',
    [Prop.LINK_TXT]: '#009CDB',
    [Prop.LINK_TXT_HOVER]: '#8BC53F',
    [Prop.BTN_BG]: '#009CDB',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#000000',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG_ACTIVE]: '#009CDB',
    [Prop.BTN_TXT_ACTIVE]: '#ffffff',
    [Prop.BTN_BG_INACTIVE]: '#C4C4BF',
    [Prop.BTN_TXT_INACTIVE]: '#4A4A4A',
    [Prop.ELEMENT]: '#009CDB',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.BORDER]: '#C4C4BF',
    [Prop.ICON]: '#009CDB',
    [Prop.ICON_HOVER]: '#000000',

    [Prop.ICON_2]: '#588A9C',
    [Prop.ICON_HOVER_2]: '#588A9C',
    [Prop.HEADER_BG]: '#000000',
    [Prop.HEADER_TXT]: '#ffffff',
    [Prop.HEADER_ICON]: '#009CDB',
    [Prop.HEADER_BG_2]: '#009CDB',
    [Prop.HEADER_TXT_2]: '#ffffff',
    [Prop.HEADER_ICON_2]: '#ffffff',
    [Prop.ODD_EVEN_OFFSET]: '#E5EDED',
  },
  [ColorProfiles.PRIMARY]: {
    [Prop.BACKGROUND]: '#009CDB',
    [Prop.HEADER]: '#FFFFFF',
    [Prop.CONTENT]: '#022B39',
    [Prop.LINK_TXT]: '#FFFFFF',
    [Prop.LINK_TXT_HOVER]: '#022B39',
    [Prop.BTN_BG]: '#8BC53F',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#000000',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG_ACTIVE]: '#8BC53F',
    [Prop.BTN_TXT_ACTIVE]: '#FFFFFF',
    [Prop.BTN_BG_INACTIVE]: '#C4C4BF',
    [Prop.BTN_TXT_INACTIVE]: '#4A4A4A',
    [Prop.ELEMENT]: '#000000',
    [Prop.ELEMENT_TXT]: '#FFFFFF',
    [Prop.BORDER]: '#FFFFFF',
    [Prop.ICON]: '#FFFFFF',
    [Prop.ICON_HOVER]: '#8BC53F',
  },
  [ColorProfiles.SECONDARY]: {
    [Prop.BACKGROUND]: '#f0f0f0',
    [Prop.HEADER]: '#000000',
    [Prop.CONTENT]: '#022B39',
    [Prop.LINK_TXT]: '#009CDB',
    [Prop.LINK_TXT_HOVER]: '#8BC53F',
    [Prop.BTN_BG]: '#009CDB',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#000000',
    [Prop.BTN_TXT_HOVER]: '#009CDB',
    [Prop.BTN_BG_ACTIVE]: '#000000',
    [Prop.BTN_TXT_ACTIVE]: '#009CDB',
    [Prop.BTN_BG_INACTIVE]: '#C4C4BF',
    [Prop.BTN_TXT_INACTIVE]: '#4A4A4A',
    [Prop.ELEMENT]: '#5a5b5c',
    [Prop.ELEMENT_TXT]: '#FFFFFF',
    [Prop.BORDER]: '#c4c4c4',
    [Prop.ICON]: '#009CDB',
    [Prop.ICON_HOVER]: '#000000',
  },
  [ColorProfiles.TERTIARY]: {
    [Prop.BACKGROUND]: '#333333',
    [Prop.HEADER]: '#FFFFFF',
    [Prop.CONTENT]: '#FFFFFF',
    [Prop.LINK_TXT]: '#ffffff',
    [Prop.LINK_TXT_HOVER]: '#009CDB',
    [Prop.BTN_BG]: '#009CDB',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#000000',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG_ACTIVE]: '#009CDB',
    [Prop.BTN_TXT_ACTIVE]: '#FFFFFF',
    [Prop.BTN_BG_INACTIVE]: '#C4C4BF',
    [Prop.BTN_TXT_INACTIVE]: '#4A4A4A',
    [Prop.ELEMENT]: '#000000',
    [Prop.ELEMENT_TXT]: '#FFFFFF',
    [Prop.BORDER]: '#FFFFFF',
    [Prop.ICON]: '#009CDB',
    [Prop.ICON_HOVER]: '#FFFFFF',
  },
  [ColorProfiles.QUATERNARY]: {
    [Prop.BACKGROUND]: '#000000',
    [Prop.HEADER]: '#FFFFFF',
    [Prop.CONTENT]: '#ffffff',
    [Prop.LINK_TXT]: '#ffffff',
    [Prop.LINK_TXT_HOVER]: '#009CDB',
    [Prop.BTN_BG]: '#009CDB',
    [Prop.BTN_TXT]: '#ffffff',
    [Prop.BTN_BG_HOVER]: '#8BC53F',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#8BC53F',
    [Prop.BTN_TXT_ACTIVE]: '#ffffff',
    [Prop.BTN_BG_INACTIVE]: '#C4C4BF',
    [Prop.BTN_TXT_INACTIVE]: '#4A4A4A',
    [Prop.ELEMENT]: '#009CDB',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.BORDER]: '#5a5b5c',
    [Prop.ICON]: '#FFFFFF',
    [Prop.ICON_HOVER]: '#009CDB',
  },
  ...componentThemesDefault,
};
